table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}
.table-wrap {
  overflow-x: auto;
}
th,
td {
  padding: 24px 16px 24px 16px;
}

tr:hover {
  background-color: rgba(221, 221, 221, 0.2);
  cursor: pointer;
}

th {
  text-align: left;
  font-size: 12px;
  border: none;
  color: #a4a4a6;
  font-weight: 200;
}

//footer

.page-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 11.625rem;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.563rem 1.375rem;
  background-color: var(--bs-light-gray-background);
}
@media (max-width: 767px) {
  .page-footer {
    left: 0;
  }
}
.row-page-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.row-page-wrap .dropdown-select {
  width: 3.438rem;
}

.row-page-wrap .page-count {
  padding: 0.438rem 0.688rem;
  margin: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: var(--bs-eerie-black);
  background-color: var(--bs-background-white);
  border: 1px solid var(--bs-pagination-border-color);
  border-radius: 0.188rem;
}
.row-page-wrap .dropdown-select .caret-icon {
  right: 0.625rem;
  transform: translateY(-50%) rotate(90deg) scale(0.7);
}
.row-page-wrap span {
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.313rem;
  color: #a4a4a6;
}
.spinner {
  width: 100%;
  height: 200px;
  display: grid;
  background-color: white;
  place-items: center;
}

.page-count-desc span {
  font-size: 13px;
}
