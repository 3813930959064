* {
  box-sizing: border-box;
  outline: 0;
}

html {
  height: stretch;
}

body {
  min-height: 100vh;
  min-height: stretch;
  font-size: 14px;
  background-color: var(--#{$variable-prefix}light-gray-background);
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--#{$variable-prefix}background-white);
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.85;
}

.cursor-pointer {
  cursor: pointer;
}

.card-gray {
  background-color: var(--#{$variable-prefix}light-gray-background);
}

.content-block {
  background: var(--#{$variable-prefix}navigation-background);
  overflow-x: auto;
}

.heading-common {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1.25rem;
  .text-content {
    max-width: 20.188rem;
    font-size: 0.625rem;
    line-height: 0.813rem;
    h1 {
      margin-bottom: 0.375rem;
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 1.438rem;
      color: var(--#{$variable-prefix}dark-text-color);
    }
    p {
      color: var(--#{$variable-prefix}light-text-color);
    }
  }
}

.inputgroup-color {
  position: relative;
  .form-floating {
    input {
      padding-left: 43px;
    }
    label {
      padding-left: 48px;
    }
  }

  [type="color"] {
    position: absolute;
    left: 12px;
    z-index: 2;
    background: transparent;
    border: 0;

    &:focus {
      background: transparent;
      box-shadow: none;
    }
  }
}

.inputgroup-btn.input-group {
  input:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  button {
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
    background: transparent;
    transform: none;
  }
  input:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    padding: 1rem 0.875rem;
    color: var(--#{$variable-prefix}dark-text-color);
    border-right: 0;
  }
  button {
    position: relative;
    border: 1px solid var(--#{$variable-prefix}input-floating-border);
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &::before {
      position: absolute;
      top: 50%;
      left: 5px;
      width: 2px;
      height: 30px;
      content: "";
      background: var(--#{$variable-prefix}input-floating-border);
      transform: translateY(-50%);
    }
  }
  &:read-only,
  &:disabled,
  .disabled {
    color: var(--#{$variable-prefix}input-diabled-text);
    background-color: var(--#{$variable-prefix}input-diabled-bg);
    border-color: var(--#{$variable-prefix}input-diabled-border);
    opacity: 1;
  }
}

.editor-divider {
  width: 2px;
  height: 15px;
  background-color: #d8d8d8;
}

.secondary-header {
  position: sticky;
  top: 62px;
  z-index: 1000;
}

@media (min-width: 767px) {
  .toast-container {
    width: 513px;
  }
}

.content-modal {
  background-color: white;
  z-index: 9999;
}

@media (max-width: 767px) {
  .secondary-header {
    z-index: 1;
  }
}

.form-control[readonly],
.tag-container[readonly] {
  background-color: white !important;
  color: #13171f !important;
}

input[type="radio"] {
  accent-color: rgb(69, 75, 147);
}
