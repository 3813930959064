.horizontal-slider {
	width: 100%;
	height: 50px;
	border-radius: 20px;
}
.horizontal-slider .example-track {
	top: 20px;
	height: 6px;
}
.example-track {
	position: relative;
	background: #d3d7d5;
}
.example-track.example-track-0 {
	background: #454b93;
	border-radius: 20px;
}
.example-track.example-track-1 {
	background: #dee2e6;
	border-radius: 20px;
}
.horizontal-slider .example-thumb {
	top: 10px;
	width: 30px;
	height: 30px;
	line-height: 38px;
}
.example-thumb {
	font-size: 10px;
	background-color: #454b93;
	border-radius: 50%;
	color: white;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}
