.main-wrapper {
  position: relative;

  /* top: 3.875rem; */
  display: flex;
}

.active-wrapper .sidebar {
  width: 2.875rem;
}

.active-wrapper .sidebar:hover {
  z-index: 3;
  width: 11.625rem;
}

.active-wrapper .dynamic-inner-sidebar {
  display: block;
}

/* Sidebar start */
.sidebar {
  position: fixed;
  z-index: 2;
  width: 11.625rem;
  min-height: calc(100% - 3.875rem);
  max-height: calc(100% - 3.875rem);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #21252c;
  transition: all 0.3s ease-out;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sidebar::-webkit-scrollbar,
.inner-sidebar::-webkit-scrollbar,
.dynamic-inner-sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar .sidebar-list {
  height: 100%;
  min-height: calc(100vh - 3.875rem);
  padding: 1.25rem 0.375rem;
  background-color: #13171f;
}

// stylelint-disable selector-no-qualifying-type
.sidebar .link:hover path,
.sidebar li.active rect,
.sidebar .link:hover rect,
.sidebar li.active path {
  stroke: #dfe3ea;
}
// stylelint-enable selector-no-qualifying-type

.sidebar-list li {
  margin: 0 0 0.625rem;
  cursor: pointer;
}

.sidebar-list .divider {
  margin: 1.25rem 0.625rem;
  border-top: 1px solid #3b3f45;
}

.sidebar-list li .link,
.dynamic-inner-sidebar .sidebar-back-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2.313rem;
  padding: 0.625rem;
  font-size: 0.8rem;
  line-height: 1.063rem;
  color: #a3a6ac;
  text-decoration: none;
  word-break: break-word;
  border-radius: 0.25rem;
  transition: all 0.3s ease-out;
}

// stylelint-disable selector-no-qualifying-type
.sidebar-list .link:hover,
.sidebar-list li.active > .link {
  color: #dfe3ea;
  background-color: #2e323a;
}
// stylelint-enable selector-no-qualifying-type

.sidebar-list .icon-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  text-transform: capitalize;
}

.sidebar-list .icon-wrap svg {
  width: 1rem;
  height: 1rem;
}

.sidebar-active .sidebar-list {
  width: 2.875rem;
}

.sidebar-list .icon-wrap .text-truncate {
  width: 6.875rem;
  padding-right: 0.625rem;
  text-transform: capitalize;
}

.sidebar-active .sidebar-list .sidebar-hidden-item {
  display: none;
}
// stylelint-disable selector-no-qualifying-type
.sidebar-active .sidebar-list li.divider {
  margin-right: 0;
  margin-left: 0;
}

.sidebar-list > li.active .inner-sidebar {
  display: block;
}
// stylelint-enable selector-no-qualifying-type
.inner-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 2.875rem;
  display: none;
  width: 8.75rem;
  padding: 0.625rem 0.375rem;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.inner-sidebar .divider,
.dynamic-inner-sidebar .divider {
  display: block;
  margin: 0.5rem -0.375rem 0.625rem;
}

.dynamic-inner-sidebar .divider {
  border-top: 1px solid #3b3f45;
}

.inner-sidebar .sidebar-back-nav,
.dynamic-inner-sidebar .sidebar-back-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.dropdown-option .link {
  margin-bottom: 0.625rem;
}

.dropdown-option ul {
  display: none;
}

.dropdown-option > .link svg {
  transform: rotate(90deg);
}

.dropdown-option-active ul {
  display: block;
}

.dropdown-option-active > .link svg {
  transform: rotate(270deg);
}

.inner-sidebar .dropdown-option-active > .link {
  color: #969ce2;
}

.inner-sidebar .dropdown-option-active > .link path {
  stroke: #969ce2;
}
// stylelint-disable selector-no-qualifying-type
.sidebar-list li .link.small-text {
  display: flex;
  align-items: center;
  min-height: inherit;
  padding: 0 0.625rem 0.625rem;
  font-size: 0.625rem;
  color: #7b90b9;
}

.sidebar-list li .link.small-text:hover {
  background-color: transparent;
}
// stylelint-enable selector-no-qualifying-type

/* Sidebar end */

/* Dynamic inner sidebar start */
.dynamic-inner-sidebar {
  position: fixed;
  top: 3.875rem;
  bottom: 0;
  left: 2.875rem;
  z-index: 2;
  display: none;
  width: 8.75rem;
  padding: 0.625rem 0.375rem;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #21252c;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dynamic-inner-sidebar .sidebar-list {
  padding: 3.75rem 0 0;
  list-style: none;
}

.dynamic-inner-sidebar .dropdown-option ul {
  padding: 0;
  list-style: none;
}

/* Dynamic inner sidebar end */

/* Fixed sidebar back nav start */
.fixed-sidebar-back {
  position: fixed;
  z-index: 2;
  width: 8.75rem;
  padding: 0.625rem 0.375rem 0;
  margin: -0.625rem 0 0 -0.375rem;
  background: #21252c;
}

.inner-sidebar .inner-sidebar-list {
  padding-top: 3.75rem;
}

/* Fixed sidebar back nav end */

/* content start */
.content-wrapper {
  position: relative;
  flex: 1;
  width: 100%;
  padding: 0 0 2.5rem 11.625rem;
}

.content-block {
  padding: 1.375rem;
  margin: 1.375rem;
  border-radius: 0.375rem;
}
.image-wrap {
  padding: 1.375rem;
  margin: 1.375rem;
}

.sidebar-toggle {
  width: 24px;
  cursor: pointer;
}
.mobile-sidebar-item {
  display: none;
}

/* content end */
@media (max-width: 768px) {
  .content-wrapper {
    padding: 0 0 2.5rem;
  }

  .sidebar {
    //transform: translateX(-190px);
    transform: translateX(-275px);
  }

  .menu-active .sidebar {
    transform: translateX(0);
  }

  .active-wrapper .dynamic-inner-sidebar {
    transform: translateX(-275px);
  }

  .menu-active .dynamic-inner-sidebar {
    display: block;
    transform: translateX(0);
  }

  .menu-active .active-wrapper .sidebar {
    transition: inherit;
  }
  .menu-active .active-wrapper .sidebar:hover {
    transition: all 0.3s ease-out;
  }
}

/* Mobile Sidebar Responsive */
@media (max-width: 767px) {
  .secondary-header {
    z-index: 1;
  }
  .sidebar,
  .active-wrapper .sidebar:hover {
    width: 275px;
  }
  .active-wrapper {
    .circle-user-profile {
      width: 21px;
      height: 21px;
      font-size: 12px;
      line-height: 13px;
      transition: all 0.3s ease-out;
    }
    .sidebar {
      &.sidebar-active {
        &:hover {
          .circle-user-profile {
            width: 21px;
            height: 21px;
            font-size: 12px;
            line-height: 13px;
            transition: all 0.3s ease-out;
          }
        }
      }
    }
  }

  .active-wrapper .sidebar:hover {
    .circle-user-profile {
      width: 30px;
      height: 30px;
      font-size: 14px;
    }
  }

  .sidebar-list li .link {
    padding: 0.725rem;
    font-size: 14px;
    line-height: 1.4rem;
  }

  .dynamic-inner-sidebar {
    left: 3.5rem;
    width: 219px;
    .sidebar-back-nav {
      gap: 0.8rem;
      padding: 0.725rem;
      font-size: 14px;
      line-height: 1.4rem;
    }
  }

  .dynamic-inner-sidebar .sidebar-list,
  .inner-sidebar .inner-sidebar-list {
    padding-top: 4.2rem;
  }

  .sidebar-list .icon-wrap {
    gap: 1.875rem;
  }

  .sidebar-list .icon-wrap svg {
    width: 20px;
    height: 20px;
  }

  .sidebar-active .sidebar-list .icon-wrap {
    flex: 1;
    justify-content: center;
  }

  .sidebar-active .sidebar-list,
  .active-wrapper .sidebar {
    width: 3.5rem;
  }

  .fixed-sidebar-back {
    width: 13.35rem;
  }

  .inner-sidebar {
    left: 3.5rem;
    width: 13.75rem;
    .sidebar-back-nav {
      gap: 0.8rem;
    }
  }

  .sidebar-list .circle-user-profile-gap {
    gap: 18px;
  }

  .circle-user-profile {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  .sidebar-active {
    .circle-user-profile {
      width: 21px;
      height: 21px;
      font-size: 12px;
      line-height: 13px;
    }
  }

  .mobile-sidebar-item {
    display: block;
    .user-pl {
      padding-left: 47px;
    }
  }
}

.secondary-header-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
