.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 6.25rem 0 3.75rem;
  background-color: var(--#{$variable-prefix}background-white);
  @media (max-width: 767px) {
    padding: 3.75rem 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 30.625rem;
    min-height: calc(100vh - 10rem);
  }
}

.login-logo {
  display: flex;
  justify-content: center;
  width: 8rem;
  height: 5rem;
  margin: 0 auto 3.063rem;
}

.mb-28 {
  margin-bottom: 1.75rem;
}

.vertical-divider {
  position: relative;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.25rem;
    margin: 1.75rem 0;
    text-align: center;
    background: rgba(205 211 222 / 32%);
  }
  span {
    display: none;
    @media (max-width: 767px) {
      display: block;
      width: 2.5rem;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: #9b9ba2;
      background-color: var(--#{$variable-prefix}background-white);
    }
  }
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.25rem;
    min-height: 17.188rem;
    text-align: center;
    content: "";
    background-color: #cdd3de;
    opacity: 0.32;
    transform: translate(-50%, 18%);
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.login-tilte {
  margin-bottom: 1.75rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: var(--#{$variable-prefix}eerie-black);
  text-align: center;
}

.forget-pass-text {
  display: block;
  margin-top: 0.625rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.938rem;
  color: var(--#{$variable-prefix}toolbox-blue);
  text-align: right;
  text-decoration: none;
  @media (max-width: 767px) {
    font-size: 0.875rem;
  }
  &:hover {
    color: var(--#{$variable-prefix}toolbox-blue);
  }
}

.sign-up-text {
  margin-top: 1.75rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.938rem;
  color: #13171f;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 0.875rem;
  }
}

.btn-social {
  width: 100%;
  @media (max-width: 767px) {
    min-height: 3.375rem;
    font-size: 0.875rem;
  }
  img {
    width: 1.25rem;
    height: 1.25rem;
    object-fit: contain;
  }
}

.button-style {
  min-height: 3rem;
  @media (max-width: 767px) {
    min-height: 3.375rem;
    font-size: 0.875rem;
  }
}

.login-footer {
  max-width: 15rem;
  margin: 1.75rem auto 0;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  color: #13171f;
  text-align: center;
  @media (max-width: 767px) {
    max-width: 18.75rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}

.link-text {
  color: var(--#{$variable-prefix}toolbox-blue);
  text-decoration: underline;
  &:hover {
    color: var(--#{$variable-prefix}toolbox-blue);
  }
}

.phone-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.75rem;
  .form-floating {
    flex: 1;
  }
}

.select-option-wrap {
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    right: 0.875rem;
    display: inline-block;
    width: auto;
    color: rgba(var(--bs-primary-color), 0.75);
    vertical-align: middle;
    transition: transform 0.2s ease-in-out;
    transform: translateY(-50%) rotate(90deg);
  }
  .form-select {
    width: 4.75rem;
    padding: 1rem;
    color: var(--#{$variable-prefix}eerie-black);
    @media (max-width: 767px) {
      width: 5.5rem;
      min-height: 3.375rem;
      font-size: 0.875rem;
    }
  }
}

.login-logo-header {
  margin: 0 auto 0.938rem;
  .login-logo {
    margin-bottom: 1.25rem;
  }
}

.login-heading-title {
  margin-bottom: 1.75rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.438rem;
  color: #13171f;
  text-align: center;
}

.btn-position {
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  padding: 0.938rem;
  background-color: transparent;
  transform: translateY(-50%);
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
}

.login-form {
  .form-floating {
    .form-control {
      @media (max-width: 767px) {
        min-height: 3.375rem;
        &:focus,
        &:not(:placeholder-shown) {
          ~ {
            label {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
    label {
      @media (max-width: 767px) {
        font-size: 0.875rem;
        line-height: 1.125rem;
      }
    }
  }
  .form-check-label {
    @media (max-width: 767px) {
      font-size: 0.875rem;
    }
  }
}
