.filter-block {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 22px 1.375rem 0;
	gap: 2rem;
}
.filter-block .filter-icon {
	display: flex;
	align-items: center;
	font-size: 10px;
	font-weight: 600;
	line-height: 13px;
	letter-spacing: 0;
	gap: 0.5rem;
	cursor: pointer;
}
.filter-block a {
	color: var(--bs-eerie-black);
	text-decoration: none;
}
